var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('main',{class:{
      'page-main-content': true,
      'page-main-content-compressed': _vm.selectedEnroll &&  _vm.isCompressed()
  }},[_c('content-placeholders',[_c('content-placeholders-img',{staticClass:"course-detail-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('div',{staticClass:"container"},[_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')})],1)],1)],1):_c('main',{class:{
    'page-main-content': true,
    'page-main-content-compressed': _vm.isCompressed()
  },style:(_vm.$theme.darkTheme
      ? 'border-left-color: #0d0d13'
      : 'background-color: #f2f3f7; border-left-color: #F2F3F7')},[(_vm.lastAccess)?_c('div',{staticClass:"bloco"},[_c('div',{staticClass:"detail-course-image"},[_c('div',{staticClass:"course-detail"},[_c('div',{staticClass:"detail-module-name"},[_vm._v(_vm._s(_vm.lastAccess.className))]),_c('div',{staticClass:"detail-class-name line-clamp-3 "},[_vm._v(_vm._s(_vm.lastAccess.learningObjectName))]),_c('div',{staticClass:"detail-course-start center"},[_c('a',{staticClass:"waves-effect btn-start center",style:((!_vm.selectedEnroll ? '#0564b1' : {background: _vm.$theme.primaryColor})),attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.navigateToLastAccess()}}},[_c('IconPlaySingle'),_vm._v(" Continuar ")],1)])]),_c('div',{staticClass:"degrade"}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.lastAccessImage(_vm.lastAccess)),expression:"lastAccessImage(lastAccess)"}],on:{"error":function($event){$event.target.src = require('@/assets/images/default-course-image.png')}}})])]):_vm._e(),_vm._l((_vm.carouselItemsFiltered),function(classData){return _c('div',{key:classData.id,staticClass:"row"},[_c('div',{ref:'swiper-container-' + classData.id,refInFor:true,staticClass:"container"},[_c('Carousel',{attrs:{"items":classData.learningObjects,"metadata":{
              title: classData.title,
              state: classData.state,
              isPreview: classData.isPreview,
              releasedDate: classData.releasedDate,
              classId: classData.id,
              moduleId: classData.moduleId ? classData.moduleId : null,
              disciplineId: classData.disciplineId ? classData.disciplineId : null
            }},on:{"click":_vm.navigateToClass,"openLeftMenu":_vm.openLeftMenu}})],1)])}),_c('div',{staticClass:"load-more center"},[(_vm.carouselItemsFiltered.length != _vm.carouselItems.length)?_c('a',{staticClass:"waves-effect btn-load-more center",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.loadMore()}}},[_c('i',{staticClass:"material-icons"},[_vm._v("add_box")]),_vm._v("Carregar mais ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }