import $store from '../store'
import axios from 'axios';
import { AuthService } from '../services/auth.service';
import { isIOS } from "mobile-device-detect";
import { ShopWindowsService } from '@/services/shopWindow.service';

var previousPath = '';

export async function cachePreviousPath(to, from, next) {
  next(comp => {
    comp.previousPath = from;
  })
}

/**  
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  const isAuthRoute = to.matched.some(item => item.meta.isAuth)
  if (!isAuthRoute) return next();

  if (isIOS) {
    var cookies = document.cookie.split(';');
    for (var cookie of cookies) {
      var kvp = cookie.split('=');
      if (kvp[0].trim() == 'accessToken') {
        AuthService.setAccessToken(kvp[1]);
        document.cookie = 'accessToken=;Max-Age=0;path=/;domain=pagtrust.com.br'
      }
      else if (kvp[0].trim() == 'refreshToken') {
        AuthService.setRefreshToken(kvp[1]);
        document.cookie = 'refreshToken=;Max-Age=0;path=/;domain=pagtrust.com.br'
      }
      else if (kvp[0].trim() == 'shopWindowId') {
        ShopWindowsService.setCurrentShopWindow({ id: kvp[1] });
        document.cookie = 'shopWindowId=;Max-Age=0;path=/;domain=pagtrust.com.br'
      }
    }
  }

  const currentUserId = $store.state.user.currentUser.studentId
  if (currentUserId) {
    var getNotifications = $store.dispatch('notification/getNotifications'); // busca as notificações
    return Promise.all([getNotifications])
      .then(() => next());
    //return next();
  }

  var accessToken = AuthService.getAccessToken();
  if (accessToken) {
    AuthService.startKeepAlive();
    axios.defaults.headers.common['authorization'] = `bearer ${accessToken}`;

    var getUserInfo = $store.dispatch('user/getCurrent').then(() => $store.dispatch('config/getConfig')); // busca o usuário
    var getUserEnroll = $store.dispatch('enroll/getUserEnroll'); // busca a matricula
    var getNotifications = $store.dispatch('notification/getNotifications'); // busca as notificações

    return Promise.all([getUserEnroll, getUserInfo, getNotifications])
      .then(() => next());
  } else {
    return next({ name: 'login' });
  }
  //next()
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find(item => item.meta.title)

  if (pageTitle) window.document.title = pageTitle.meta.title
  next()
}
