<template>
<!-- background: url(../assets/images/bg.png); -->
  <div id="app" :class="{'mobilev':isMobile, 'deskv':!isMobile}" :style="($theme.darkTheme ? '' : 'background: #f2f3f7')">
    <UiToastList />
    <ComposedHeader ref="header" v-if="hasHeader" :layout-theme="currentLayoutTheme"></ComposedHeader>
    <router-view @openLeftMenu="headerOpenMenu($event)"></router-view>
    <ModalMyGroup :layout-theme="currentLayoutTheme"></ModalMyGroup>
    <ModalMyCertificate :layout-theme="currentLayoutTheme"></ModalMyCertificate>
    <ModalProfile :layout-theme="currentLayoutTheme"></ModalProfile>
    <ModalConfiguration :layout-theme="currentLayoutTheme"></ModalConfiguration>
    <ModalNotes :layout-theme="currentLayoutTheme"></ModalNotes>
    <ModalSupport :layout-theme="currentLayoutTheme"></ModalSupport>
    <ModalCourseFiles :layout-theme="currentLayoutTheme"></ModalCourseFiles>
    <Footer v-if="hasFooter" :layout-theme="currentLayoutTheme"></Footer>
  </div>

</template>

<script>
import ComposedHeader from './ComposedHeader.vue'
import Footer from './Footer.vue'
import UiToastList from "@/components/UI/UiToastList";
import ModalProfile from '@/pages/modals/Profile'
import ModalConfiguration from '@/pages/modals/Configuration'
import ModalNotes from '@/pages/modals/Notes'
import ModalSupport from '@/pages/modals/Support'
import ModalMyGroup from '@/pages/modals/MyGroup'
import ModalMyCertificate from '@/pages/modals/MyCertificate'
import ModalCourseFiles from '@/pages/modals/CourseFiles'

import { mapState } from 'vuex'

export default {
  name: 'AppLayout',
  components: {
    ComposedHeader,
    Footer,
    ModalProfile,
    ModalConfiguration,
    ModalNotes,
    ModalMyGroup,
    ModalMyCertificate,
    ModalSupport,
    ModalCourseFiles,
    UiToastList
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      currentLayoutTheme: state => state.user.currentUser.layoutTheme,
      selectedEnroll: (state) => state.enroll.current,
    }),
    hasHeader() {
      const routeMetaHeader = this.$route.meta.header;

      switch(routeMetaHeader) {
        case 'no-header':
          return false;
        case 'dark':
          this.$store.dispatch('user/setCurrentTheme', 'dark');
          return true;
        case 'light':
          this.$store.dispatch('user/setCurrentTheme', 'light');
          return true;
      }
    },
    hasFooter() {
      const routeMetaFooter = this.$route.meta.footer;

      switch(routeMetaFooter) {
        case 'no-footer':
          return false;
        case 'dark':
          this.$store.dispatch('user/setCurrentTheme', 'dark');
          return true;
        case 'light':
          this.$store.dispatch('user/setCurrentTheme', 'light');
          return true;
      }
    },
    headerMeta() {
      const headerMetaParams = this.$route.meta.headerMeta;
      if(headerMetaParams)
        return headerMetaParams;
      else
        return { };
    },
  },
  methods: {
    headerOpenMenu(classId) {
      this.$refs.header.openLeftMenu(classId);
    }
  }
}
</script>
