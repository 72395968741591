<template>
  <div :style="defaultStyle">
    <trustflix-header />
    <main>
      <div id="trustflix">
        <!-- content -->
        <div :class="{ 'trustflix-content': true, shrink: sidenavIsOpen && !shopwindowIsLoading }" ref="trustflixContent">

          <content-placeholders v-if="shopwindowIsLoading" class="row">
            <div class="box-banner">
              <div class="box-gradient"></div>
              <!-- <img src="@/assets/images/drop.png" alt="" srcset="" /> -->
              <content-placeholders-img class="section-placeholder section-placeholder--header loading-dark" />
            </div>
            <br />
            <div class="box-content">
              <content-placeholders-img class="section-placeholder section-placeholder--progress loading-dark" />
              <br />
              <content-placeholders-img class="section-placeholder section-placeholder--dynamic loading-dark" />
            </div>
          </content-placeholders>

          <div class="row" v-else>
            <div class="box-banner">
              <div class="box-gradient"></div>
              <!-- <img src="@/assets/images/drop.png" alt="" srcset="" /> -->
              <img v-lazy="loadImage(image)" alt="" />
            </div>

            <!-- Progresso -->
            <ProgressSection :primaryColor="primaryColor" />

            <br />
            <!-- Dinamico -->
            <div
              class="trustflix-dynamic-section"
              v-for="(section, index) in sortedSections"
              :key="'DynamicSection_' + section.id + '_' + index"
            >
              <DynamicSection :section="section" @clickBuyable="swipeBuySectionTo" />
              <br />
            </div>
            <!-- Vendas -->
            <BuySection
              v-if="hasBuySection"
              ref="buySection"
              title="Mais vendidos"
              sub="Lançamento"
            >
              <i class="material-icons" icon-color slot="icon">local_offer</i>
            </BuySection>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ProgressSection from "@/components/Trustflix/ProgressSection";
import DynamicSection from "@/components/Trustflix/DynamicSection";
import BuySection from "@/components/Trustflix/BuySection";
import CourseBox from "@/components/MyCoursesCourseBox";
import { mapState, mapGetters } from "vuex";
import { isMobile, isIOS } from "mobile-device-detect";
import TrustflixHeader from "@/layout/TrustflixHeader.vue";
import ProgressBar from "@/components/UI/ProgressBar";
import HeaderPoster from "@/assets/images/posters/evf-video.png";

export default {
  name: "Trustflix",
  components: {
    CourseBox,
    ProgressSection,
    DynamicSection,
    BuySection,
    TrustflixHeader,
    ProgressBar
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.shopWindow.primaryColor,
      image: (state) => state.shopWindow.image,
      sections: (state) => state.shopWindow.dynamicSections,
      isValid: (state) => state.shopWindow.isValid,
      sidenavIsOpen: (state) => state.config.sidenavIsOpen,
    }),
    ...mapGetters({
      hasBuySection: "shopWindow/hasBuySection",
      shopwindowIsLoading: "shopWindow/isLoading",
    }),
    sortedSections() {
      return _.sortBy(this.sections, "sequence");
    },
  },
  data() {
    return {
      defaultStyle: {
        "--primary-color": "#0D0D13",
        "--text-color": "#FFFFFF",
      },
    };
  },
  methods: {
    async init() {
      this.$store.dispatch("enroll/clearCurrentEnroll");
      this.setColors();

      this.$gtag.pageview({
        page_title: "trustflix",
        page_path: "/p/trustflix",
      });

      const shopwindow = await this.$store.dispatch(
        "shopWindow/getCurrentFullGrid"
      );

      if (!shopwindow) {
        return this.navTo("/p/my-courses");
      }

      this.setColors();

      this.$updateTheme({
        darkTheme: true,
        primary: shopwindow.primaryColor,
      });
    },
    getTextColor(primaryColor) {
      const brightness = (R, G, B) => (R * 299 + G * 587 + B * 114) / 1000;
      const MAX_BRIGHTNESS = 123;

      const r = parseInt(primaryColor.slice(1, 3), 16);
      const g = parseInt(primaryColor.slice(3, 5), 16);
      const b = parseInt(primaryColor.slice(5, 7), 16);

      if (brightness(r, g, b) > MAX_BRIGHTNESS) return "#333333";
      return "#FFFFFF";
    },
    setColors() {
      if (!this.primaryColor) return;
      this.defaultStyle["--primary-color"] = this.primaryColor;
      this.defaultStyle["--text-color"] = this.getTextColor(this.primaryColor);
    },
    loadImage(image) {
      return {
        src: image || HeaderPoster,
        loading: HeaderPoster,
        error: HeaderPoster,
      };
    },
    swipeBuySectionTo(courseId) {
      const buySection = this.$refs.buySection;
      if (buySection) buySection.swipeTo(courseId);
    },
    navTo(path) {
      if (this.isPreview) return;

      if (path === "/p/my-courses") {
        this.$updateTheme({
          darkTheme: false,
          primary: "#0564b1",
          secondary: "#ffffff",
        });
      }

      this.$router.push(path);
    },
  },
  async mounted() {
    this.init();
  },
  watch: {
    sections(sects) {
      if (!sects.length && !this.isValid) this.navTo("/p/my-courses");
    },
  },
};
</script>

<style scoped lang="scss">
#app {
  @font-face {
    font-family: "Material Icons";
    font-display: block;
  }

  header,
  header nav,
  main,
  footer {
    background-color: rgb(0, 0, 0) !important;
  }

  #trustflix {
    display: flex;
    min-height: 100vh;
  }

  .trustflix-content {
    width: 100%;

    .box-content {
      background: rgb(0, 0, 0);
      padding: 15px;

      .content-title {
        font-weight: 600;
        border-left: 3px solid var(--primary-color);
        padding-left: 20px;
      }
      .box-trustflix-itens {
        width: 100%;
        height: 300px;
        background: rgb(0, 0, 0);
        overflow: hidden;
        margin: 10px 0;
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;

        .trustflix-item {
          margin-right: 10px;
          // border: 1px solid rgb(106, 255, 0);
          border-radius: 20px;
          width: 220px;
          height: 100%;
          overflow: hidden;
          // padding: 5px;

          .item-img {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            object-fit: contain;
          }
        }
      }
    }

    .box-main {
      width: 20% !important;
      height: 200px !important;
    }

    .box-course {
      height: 200px !important;

      .box-img img {
        max-width: 100%;
        height: auto;
      }
    }

    .box-banner {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      position: relative;

      .box-gradient {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 1) 99%
        );
      }

      img {
        border-style: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-width: 100%;
        max-height: 400px;
      }
    }

    .section-placeholder {
      border-radius: 20px;

      &.section-placeholder--progress {
        height: 286px;
      }
      &.section-placeholder--dynamic {
        height: 630px;
      }
      &.section-placeholder--header {
        border-radius: 0;
        height: 300px;
      }
    }

    transition: border 300ms ease;
    border-left: 0 solid #0d0d13;
    @media screen and (min-width: 1300px) {
      &.shrink {
        // border-left-width: 300px;
        border-left-width: 260px;
        transition: border 320ms ease;
      }
    }
  }
}
</style>
