<template>
  <div class="box-slider-courses" ref="container" v-if="section">
    <div class="box-content">
      <header class="box-content-header">
        <div class="header-textual">
          <h4 class="content-title">{{ section.title }}</h4>
          <p class="content-sub">{{ section.subtitle }}</p>
        </div>
        <div class="header-carousel-bts">
          <CarouselNavPrevBT :class="`swipe-button-prev-vert-${_uid}`" />
          <CarouselNavNextBT :class="`swipe-button-next-vert-${_uid}`" />
        </div>
      </header>

      <div class="box-trustflix-itens">
        <swiper
          ref="verticalSwiper"
          :options="swiperOption"
          class="swiper-container"
        >
          <swiper-slide
            v-for="(item, index) in sortedContent"
            :key="'dynamicSection_' + section.id + '_' + item.id + '_' + index"
            :title="
              item.name +
                (!item.classLearningObjectDeleted
                  ? ''
                  : ' - Conteúdo não encontrado')
            "
          >
            <a
              :class="{
                'trustflix-item': true,
                'trustflix-item--unavailable': !getIsAvailable(item),
                'trustflix-item--buyable': showBuyOverlay(item) && hasBuySection,
                'trustflix-item--removed': item.classLearningObjectDeleted
              }"
              href="javascript:;"
              @click="redirectCoursePage(item)"
            >
              <div class="item-img">
                <img
                  v-if="!item.autoGenerate"
                  v-lazy="loadImage(item)"
                  :alt="item.name"
                />
                <GeneratedItem
                  v-else
                  :module="item.className"
                  :title="item.name"
                  :image="loadImage(item, 200)"
                  
                />

                <ItemLabels
                  v-if="!item.classLearningObjectDeleted"
                  :item="item"
                  :isBuyable="showBuyOverlay(item)"
                />
              </div>
              <div
                v-if="showBuyOverlay(item) && hasBuySection"
                class="trustflix-item-buy-overlay"
                @click="redirectCoursePage(item, true)"
              >
                <i class="material-icons">shopping_cart</i>
                <strong>{{ item.courseName }}</strong>
                Ir para a {{ buySectionTitle }}
              </div>
            </a>
          </swiper-slide>
          <div class="swiper-pagination-vert" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@voxel/vue-awesome-swiper';
import { formatDate } from '@/filters/formatDate';
import '@voxel/swiper/css/swiper.css';
import CarouselNavPrevBT from '@/components/UI/CarouselNavPrevBT.vue';
import CarouselNavNextBT from '@/components/UI/CarouselNavNextBT.vue';
import { mapGetters } from 'vuex';

import ItemLabels from './ItemLabels.vue';
import GeneratedItem from './GeneratedItem';

export default {
  name: 'DynamicSection',
  props: ['section'],
  components: {
    Swiper,
    SwiperSlide,
    CarouselNavPrevBT,
    CarouselNavNextBT,
    ItemLabels,
    GeneratedItem
  },
  mounted() {
    if (this.$refs.container)
      this.ro = new ResizeObserver(this.onResize).observe(this.$refs.container);
  },
  data() {
    return {
      // ro: null,
      resizeDebounce: 0,
      swiperOption: {
        navigation: {
          nextEl: `.swipe-button-next-vert-${this._uid}`,
          prevEl: `.swipe-button-prev-vert-${this._uid}`
        },
        pagination: {
          el: '.swiper-pagination-vert',
          dynamicBullets: true,
          clickable: true
        },
        // ro: this.ro,
        showAll: true,
        observer: true,
        observeParents: true,
        updateOnWindowResize: true,
        breakpoints: {
          450: {
            slidesPerView: 'auto',
            spaceBetween: 12.5
          },
          350: {
            slidesPerView: 'auto',
            spaceBetween: 15
          },
          0: {
            slidesPerView: 1.1,
            spaceBetween: 15
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      offerByCourseId: 'shopWindow/offerByCourseId',
      hasBuySection: 'shopWindow/hasBuySection',
      buySectionTitle: 'shopWindow/buySectionTitle',
      isSmall: 'dom/isSmall'
    }),
    sortedContent() {
      // return this.section.content;
      return _.sortBy(this.section.content, 'sequence');
    }
  },
  methods: {
    showBuyOverlay(item) {
      return this.getIsCourse(item) && !item.enrollId;
    },
    getHasOffer(courseId) {
      if (!courseId) return false;
      return this.offerByCourseId(courseId);
    },
    getIsAvailable(item) {
      return (
        this.getIsPublished(item) &&
        (!this.getIsCourse(item) || item.enrollId) &&
        !item.comingSoon &&
        !item.pendingPayment &&
        !this.getIsNone(item)
      );
    },
    getIsPublished(item) {
      return (
        item.state === 'Published' ||
        (item.state === 'Scheduled' && !this.getIsFutureScheduled(item))
      );
    },
    getIsFutureScheduled(item) {
      return (
        item.state === 'Scheduled' &&
        (item.daysOfReleased ||
          (item.releaseDate && this.dateIsFuture(item.releaseDate)))
      );
    },
    getItemLabels(item) {
      const labels = [];
      //if (item.isExtension) labels.push('EXTENSÃO');
      const { releaseDate, startDate, isExtension } = item;
      const availableDay = releaseDate || startDate;

      if (availableDay) labels.push(this.getScheduledRelease(availableDay));

      return labels.filter(Boolean);
    },
    getScheduledRelease(date) {
      if (!date) return '';

      const dateObj = new Date(date);
      const todayObj = new Date();

      const formattedDate = formatDate(dateObj);
      const formattedToday = formatDate(todayObj);

      if (formattedDate === formattedToday && !this.dateIsPast(date)) {
        return `LIBERA ÀS ${dateObj.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })}`;
      } else if (this.dateIsFuture(date)) {
        const sameYear = dateObj.getFullYear() === todayObj.getFullYear();
        return `DISPONÍVEL EM ${formattedDate.slice(
          0,
          sameYear ? 5 : undefined
        )}`;
      }
    },
    getIsExternal(item) {
      return item.type === 'External';
    },
    getIsNone(item) {
      return item.type === 'None' || (this.getIsExternal(item) && !item.link);
    },
    getIsCourse(item) {
      return item.type === 'Course';
    },
    getTimeDiff(date) {
      return Number(new Date(date)) - Number(new Date());
    },
    dateIsFuture(date) {
      if (!date) return false;
      return this.getTimeDiff(date) > 0;
    },
    dateIsPast(date) {
      if (!date) return false;
      return this.getTimeDiff(date) < 0;
    },
    onResize() {
      clearTimeout(this.resizeDebounce);
      this.resizeDebounce = setTimeout(() => {
        this.$refs.verticalSwiper?.$swiper.update();
      }, 200);
    },
    getFallbackImage(loType, formType = 'Exercise') {
      switch (loType) {
        case 'BdQ':
          switch (formType) {
            case 'Proof':
            case 'Simulated':
              return require('@/assets/images/posters/proof-simulated.png');
            case 'SurveyResearch':
              return require('@/assets/images/posters/surveyresearch.png');
            case 'Exercise':
              return require('@/assets/images/posters/exercise.png');
          }
        case 'Pdf':
          return require('@/assets/images/posters/evf-pdf-v.png');

        case 'HtmlContent':
          return require('@/assets/images/posters/evf-html-v.png');

        case 'ExternalContent':
          return require('@/assets/images/posters/evf-generic-v.png');

        default:
          return require('@/assets/images/posters/evf-video-v.png');
      }
    },
    loadImage(item, height = 504) {
      const fallbackImg = this.getFallbackImage(
        item.classLearningObjectType,
        item.formType
      );

      const itemImg = item.verticalImage
        ? item.verticalImage + '?h=' + height
        : fallbackImg;

      return {
        src: itemImg,
        loading: fallbackImg,
        error: fallbackImg
      };
    },
    redirectCoursePage(item, overrideMobile = false) {
      if (
        this.showBuyOverlay(item) &&
        this.hasBuySection &&
        (!this.isSmall || overrideMobile)
      ) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
        return this.$emit('clickBuyable', item.courseId);
      }
      if (!this.getIsAvailable(item)) return;
      if (this.getIsExternal(item)) {
        const hasProtocol = /^https?:\/\//i.test(item.link);
        return window.open((!hasProtocol ? 'https://' : '') + item.link);
      }

      const isLearningObject = Boolean(item.learningObjectId);
      if (isLearningObject) {
        return this.$router.push({
          name: 'class',
          params: {
            enrollId: item.enrollId,
            id: item.learningObjectId
          }
        });
      }
      this.$updateTheme({
        darkTheme: true,
        secondary: '#0d0d13'
      });

      return this.$router.push({
        name: 'course',
        params: { id: item.enrollId },
        query: { redirect: item.classId ?? 'last' }
      });
    }
  },
  filters: {
    formatProgress(progress) {
      return `${progress.toLocaleString('pt-BR', {
        maximumFractionDigits: 2
      })} %`;
    }
  }
};
</script>

<style lang="scss" scoped>
$card-width: 237px;
$card-height: 504px;

/* 51/100 aspect ratio */
$aspect-ratio: calc(50000% / 255);

.box-slider-courses {
  background: #141414;
  padding: 14px 0;
  margin: 0 20px;
  border-radius: 8px;
}

/* trustflix */

.box-content {
  position: relative;
}

.box-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;

  .header-carousel-bts {
    display: flex;
  }
}

.content-title {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.content-sub {
  color: #ffffff80;
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 300;
}

.box-trustflix-itens {
  width: 100%;
  overflow: hidden;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .trustflix-item {
    position: relative;
    display: block;
    border-radius: 20px;

    max-width: 100%;
    height: $card-height;
    width: $card-width;
    overflow: hidden;

    transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    cursor: pointer;

    &.trustflix-item--unavailable {
      position: relative;
      filter: grayscale(1);
      cursor: not-allowed;

      &.trustflix-item--buyable {
        cursor: pointer;
      }
    }

    &.trustflix-item--removed {
      filter: grayscale(1);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-image: url("../../assets/icons/missingFile.svg");
        filter: drop-shadow(2px 4px 2px rgb(0 0 0 / 0.5));

        background-repeat: no-repeat;
        background-size: 72px;
        background-position: 95% calc(95% - 0px);
      }

      .item-img {
        filter: brightness(0.6);
      }
    }

    .trustflix-item-labels {
      position: absolute;
      top: 12px;
      right: 12px;
      left: 12px;

      color: white;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &,
      & i {
        font-size: 11px;
      }

      & div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;

        &.right-labels {
          justify-content: flex-end;
          margin-left: auto;
        }

        &.left-labels {
          justify-content: flex-start;
          white-space: nowrap;
        }
      }

      & div > * {
        height: auto;
        min-height: 16px;
        background: #383838;
        padding: 2px 8px;
        border-radius: 3px;

        margin: 0 2px;

        display: inherit;
        align-items: center;
      }
    }
    .trustflix-item-buy-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      color: white;
      opacity: 0;
      /* top: 50%; */
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 0;
      /* transform: translateY(-50%); */
      background-color: #000000be;
      transition: opacity 200ms ease-in-out;
      box-shadow: 0px 0 4px 4px #00000090;
      text-align: center;

      strong {
        font-weight: bold;
      }
    }

    .label-comingsoon {
      // color: red;
      padding-left: 7px;
    }

    &:hover,
    &:focus {
      box-shadow: 0px 7px 30px 0px black, 0px 7px 30px 0px var(--primary-color);
      /* box-shadow: 0px 7px 40px 0px rgba(128, 89, 255, 0.54); */
      .trustflix-item-buy-overlay {
        opacity: 1;
      }
    }
  }
}

.swiper-slide {
  width: fit-content;
  // margin-left: 20px;
  // margin-right: 20 !important;
  margin-bottom: 10px !important;
  display: flex;

  &:first-child::before {
    width: 20px;
  }

  &::before,
  &:last-child::after {
    content: '';
    height: 100%;
    width: 12.5px;
    background-color: transparent;
    height: $card-height;
    display: flex;
  }

  &:last-child::after {
    width: 20px;
  }
}

.box-trustflix-itens .item-img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 20px;

  background-color: #1f1f1f;

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: 20px;
    object-fit: cover;
  }
}

.swiper-container {
  width: 100%;
  padding-top: 0px;
}

.swiper-pagination-vert {
  margin: 16px auto 0;
  width: fit-content;
  transform: unset !important;
}

@media screen and (max-width: 500px) {
  .box-slider-courses {
    margin: 0 10px;
  }
}

@media screen and (max-width: 450px) {
  .box-trustflix-itens {
    .trustflix-item {
      padding-top: $aspect-ratio;
      height: unset;
    }

    .swiper-slide {
      &:first-child::before {
        width: 12.5px;
      }

      &:last-child {
        margin-right: 12.5px;
        &::after {
          width: 0;
        }
      }
    }
  }
}

i.primary-colored {
  color: var(--primary-color);
  font-weight: bolder;
}
</style>

<style>
.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: #979797;
}
.swiper-pagination-bullet-active {
  background: var(--primary-color) !important;
}
</style>
