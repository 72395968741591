<template>
  <div
    id="box-login"
    :class="{ overlay: !showLogo }"
    ref="boxLogin"
    :style="colorsVars"
  >
    <UiToastList />

    <div class="login-container">
      <div class="login-logo">
        <div v-show="!isExternalUrl || !loadingLogo">
          <AppLogo v-if="showPlatformLogo" darkMode width="180px" />
          <img
            v-else
            v-show="showLogo"
            class="logo-cliente"
            id="logoImg"
            :src="customLogoSrc"
            @error="fallbackToPlatformLogo"
          />
        </div>
      </div>

      <div class="login-main">
        <h1>Acesse sua conta</h1>
        <span id="form-divider" :class="{ 'waiting-data': fetching }" />
        <form class="login-form" @submit.prevent="triggerSubmit">
          <div class="input-field s6">
            <div class="input-field s6 row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#FFFFFF"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
                />
              </svg>
              <input
                id="login"
                type="text"
                name="email"
                :class="{ 'overlay-txt': showLogo }"
                autocomplete="email"
                :value="email"
                @keydown.enter="onEmailEnter"
                @input="
                  $emit('input', {
                    target: 'email',
                    value: $event.target.value
                  })
                "
              />
              <label
                for="login"
                class="active"
                :class="{ 'overlay-txt': showLogo }"
                >Login</label
              >
            </div>
          </div>

          <div class="input-field s12 row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
              />
            </svg>
            <input
              name="password"
              ref="password"
              id="password"
              :type="!showPassword ? 'password' : 'text'"
              :class="{ 'overlay-txt': showLogo }"
              autocomplete="password"
              :value="password"
              @input="
                $emit('input', {
                  target: 'password',
                  value: $event.target.value
                })
              "
            />
            <label
              for="password"
              class="active"
              :class="{ 'overlay-txt': showLogo }"
              >Senha</label
            >
          </div>
          <div class="login-password-options">
            <div class="login-mostrar-senha">
              <label>
                <input
                  type="checkbox"
                  class="filled-in"
                  v-model="showPassword"
                />
                <span :class="{ 'checkbox-box': true, 'overlay-txt': showLogo }"
                  >Mostrar senha</span
                >
              </label>
            </div>
            <div class="login-esqueceu-senha" @click="$emit('forgotPassword')">
              <template v-if="!submittingPasswordResetLink">
                <span :class="{ 'overlay-txt': showLogo }">
                  Esqueceu sua senha?
                </span>
              </template>
              <div v-else>
                <div class="preloader-wrapper small active">
                  <div class="spinner-layer forgot">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <button
              class="waves-effect btn-large btn-login-trustflix"
              type="submit"
              :class="{ 'waiting-data': fetching }"
            >
              <template v-if="!submitting">
                ENTRAR
              </template>
              <template v-else>
                <div class="preloader-wrapper small active">
                  <div class="spinner-layer">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </template>
            </button>
          </footer>
        </form>
      </div>
      <iframe
        v-if="isExternalUrl"
        ref="courseIframe"
        :src="appUrl + 'login-integration'"
        width="0"
        height="0"
        style="display: none"
      ></iframe>
      <div class="login-powered">
        Powered by
        <AppLogo darkMode monochrome width="113" />
      </div>
    </div>
  </div>
</template>
<script>
import AppLogo from "@/components/UI/AppLogo.vue";
import UiToastList from "@/components/UI/UiToastList.vue";
import { AuthService } from "@/services/auth.service";
import { mapState } from "vuex";

export default {
  name: "LoginTemplate",
  components: { AppLogo, UiToastList },
  props: {
    email: String,
    password: String,

    isExternalUrl: Boolean,

    fetching: Boolean,
    submitting: Boolean,
    submittingPasswordResetLink: Boolean,

    customLogoSrc: String,
    showPlatformLogo: { type: Boolean, default: true },
    showLogo: Boolean,
    loadingLogo: Boolean,

    colorsVars: {
      type: Object,
      default: () => ({ "--primary-color": "#06BA94" })
    }
  },
  
  computed: {
    ...mapState({
      enrolls: state => state.enroll.enrolls
    })
  },
  data() {
    return {
      appUrl: process.env.VUE_APP_EAD_URL,
      showPassword: false
    };
  },
  
  async mounted() {
    let hash = this.$route.query.h;
    let courseId = this.$route.query.c;
    //this.isExternalUrl = window.location.href != process.env.
    if(hash && courseId){
       await AuthService.integrationLogin(hash);        
       await this.$store.dispatch("user/getCurrent");
       await this.$store.dispatch("notification/getNotifications");
       await this.$store.dispatch("config/getConfig");
       await this.$store.dispatch("user/updateProfilePic");
       await this.$store.dispatch("notification/initLoopTimeout");
       await this.$store.dispatch("enroll/getUserEnroll");
      
      const enroll = this.enrolls.find(e => {
        return e.courseId == courseId;
      });
      if (!enroll || !enroll.id) {
        this.$store.commit("toast/NEW", {
          type: "error",
          message: "Você não tem uma matrícula válida para esse curso.",
          page: "login"
        });
        this.loading = false;
        return;
      }

      const accessToken = AuthService.getAccessToken();
      const refreshToken = AuthService.getRefreshToken();
      debugger;
      this.sendMessageToPlatform({
        isExternalLogin: true,
        accessToken: accessToken,
        refreshToken: refreshToken,
        messageType: "course-login"
      });

      window.location = `${process.env.VUE_APP_EAD_URL}p/course/${enroll.id}`;


    }
  },
  methods: {
    fallbackToPlatformLogo() {
      this.showPlatformLogo = true;
    },
    onEmailEnter(ev) {
      ev.preventDefault();
      if (this.password && this.password.length) {
        this.triggerSubmit();
      } else if (this.$refs.password) this.$refs.password.focus();
    },
    triggerSubmit(event) {
      this.$emit("submit", event);
    },

    sendMessageToPlatform({
      isExternalLogin,
      accessToken,
      refreshToken,
      shopWindow,
      messageType
    }) {
      this.$refs.courseIframe.contentWindow.postMessage(
        {
          courseUrl: isExternalLogin ? window.location.href : undefined,
          shopWindow: shopWindow || undefined,
          accessToken,
          refreshToken,
          type:
            messageType || (isExternalLogin ? "course-login" : "trustflix-login")
        },
        this.appUrl
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes walkIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    -webkit-opacity: 0;
  }

  to {
    -webkit-opacity: 1;
  }
}

@-webkit-keyframes walkIn {
  from {
    -webkit-transform: translateY(50px);
    -webkit-opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}

@font-face {
  font-family: "Material Icons";
  font-display: block !important;
}

#box-login {
  background-color: black;
  height: unset;
  min-height: 100vh;
  padding: 0;

  .login-container {
    width: 500px;
    max-width: 90vw;

    animation: fadeIn 1s forwards;
    -webkit-animation: fadeIn 1s forwards;

    .login-logo {
      height: 120px;

      .vue-content-placeholders {
        width: 180px;
        height: 100px;

        .vue-content-placeholders-img {
          height: 100px;
        }
      }

      .logo-cliente {
        max-width: 180px;
        animation: walkIn 1s forwards;
        -webkit-animation: walkIn 1s forwards;
      }
    }

    .login-main {
      background-color: #141414;
      box-shadow: rgba(29, 29, 29, 0.81) -15px 15px 90px 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);

      border-radius: 30px;

      h1 {
        user-select: none;
        padding: 0 48px;
        font-weight: 300;
        font-size: 36px;
        margin: 38px 0;
        text-align: center;
      }

      .btn-login-trustflix,
      #form-divider {
        transition: background 300ms ease;
      }

      #form-divider {
        height: 6px;
        width: 100%;
        background: linear-gradient(
          90deg,
          black 18%,
          var(--primary-color) 100%
        );
        background-size: 200%;
        display: block;

        &:not(.waiting-data) {
          background-size: 100%;
        }
      }

      .login-form {
        padding: 32px 72px 48px;
      }

      span,
      .overlay-txt {
        color: white !important;
      }

      input {
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid transparent !important;

        &#login,
        &#password {
          border-radius: 12px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          border: 1px solid gray !important;
          -webkit-text-fill-color: white !important;
          -webkit-box-shadow: #292929 0px 0px 0px 1000px inset !important;
          background-color: #292929 !important;
          background-clip: content-box !important;
        }

        &:focus,
        &:-webkit-autofill:focus {
          border-color: var(--primary-color) !important;
        }

        & ~ label {
          margin-left: 3rem;
          user-select: none;

          &.active {
            color: var(--primary-color) !important;
            filter: contrast(0.5);
          }
        }

        & ~ span.checkbox-box::after {
          background-color: transparent;
        }

        &:checked ~ span.checkbox-box::after {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }

      .input-field {
        position: relative;

        & > label:not(.label-icon).active {
          transform: translateY(-18px) scale(0.8);
        }

        & i,
        & svg {
          position: absolute;
          width: 3rem;
          font-size: 1.5rem;
          height: 1.5rem;
          top: 0.85rem;
          text-align: center;
        }

        input {
          padding-left: 3rem;
          width: calc(100% - 3rem);
        }
      }

      @media screen and (max-width: 500px) {
        h1 {
          font-size: 6vw;
        }

        .login-form {
          padding: 32px 28px;
        }
      }

      @media screen and (max-width: 320px) {
        .login-password-options {
          flex-direction: column;

          & > div {
            margin: 6px 0;
          }
        }

        .btn-login-trustflix {
          margin-top: 28px;
        }
      }
    }
  }
}

.login-container {
  user-select: none;
}

.login {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}

.spinner-layer {
  border-color: #eee !important;
}

.forgot {
  border-color: #0564b1 !important;
}

.btn-login-trustflix {
  background-color: var(--primaryColor) !important;
  background-image: linear-gradient(
    180deg,
    var(--primary-color) 1%,
    rgba(0, 0, 0, 0.1) 400%
  );
  width: 100%;
  border-radius: 7px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 40px;
  font-weight: 500;
  white-space: nowrap;
  margin-top: 32px;

  .preloader-wrapper.small {
    width: 24px;
    height: 24px;
    margin-top: 0;
  }
}
</style>
