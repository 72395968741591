import {
  BaseService
} from './base.service'
import {
  ErrorWrapper,
  ResponseWrapper
} from './util'

export class ShopWindowsService extends BaseService {
  static get entity() {
    return 'shopWindows'
  }


  static async getCurrent(url) {
    try {
      const response = await this.request({
        auth: true
      }).get(`${process.env.VUE_APP_EAD_API_URL}/shopwindows`, {
        params: {
          url
        }
      });

      if(response.data != null)
        this.setCurrentShopWindow(response.data);

      return new ResponseWrapper({
        status: 200,
        data: {
          success: true
        }
      }, response.data);

    } catch (error) {
      const message = error.response.data && typeof (error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
  static async getCurrentFullGrid() {
    try {
      var data = this.getCurrentShopWindow();

      const response = await this.request({
        auth: true
      }).get(`${process.env.VUE_APP_EAD_API_URL}/shopwindows/${data}`);

      return new ResponseWrapper({
        status: response.status,
        data: {
          success: true
        }
      }, response.data);

    } catch (error) {
      const message = error.response.data && typeof (error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
  static setCurrentShopWindow(data) {
    if (data.id) {
      sessionStorage.setItem('shopWindowId', data.id)
    };
  }
  static clearCurrentShopWindow() {
    sessionStorage.removeItem('shopWindowId');
  }
  static getCurrentShopWindow() {
    return sessionStorage.getItem('shopWindowId');
  }
}
